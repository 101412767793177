// icons
//import playIcon from '@iconify/icons-carbon/play';
// @mui
//import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Stack, Container, Typography, Button } from '@mui/material';
// components
import Image from '../../../components/Image';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 0),
  [theme.breakpoints.up('md')]: {
    //height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
}));

// ----------------------------------------------------------------------

export default function LandingHero() {
  return (
    <RootStyle>
      <Container>
        <Grid
          container
          columnSpacing={10}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={5}
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            <Stack spacing={5}>
              <Typography variant="overline" sx={{ color: 'primary.main' }}>
                Platform To Help Club Owners and Parents
              </Typography>

              <Typography variant="h1">
                Building and managing your kids club made easy.
              </Typography>

              <Typography sx={{ color: 'text.secondary' }}>
                Coaching and teaching kids in sports or other learning
                activities take a lot of time. We provide cloud tools to help
                busy club owners and coaches to manage their students,
                communicate with parents, organize club events. All can be down
                for free when you create your club space.
              </Typography>

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent={{ xs: 'center', md: 'unset' }}
                spacing={3}
              >
                <Button variant="contained" size="large">
                  Free To Use
                </Button>
              </Stack>
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={7}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          >
            <Image alt="sports" src="/static/images/sports.svg" />
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
