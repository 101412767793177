import { combineReducers } from '@reduxjs/toolkit';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as clubInfoReducer } from 'src/slices/clubinfoSlice';
import { reducer as userReducer } from 'src/slices/userprofileSlice';

import { reducer as userClubsReucer } from 'src/slices/userClubsSlice';
import { reducer as calendarReducer } from 'src/slices/calendarSlice';
import { reducer as classesAndEventsReducer } from 'src/slices/classesAndEventsSlice';
import { reducer as scheduleClassesReducer } from 'src/slices/scheduleSlice';
import { reducer as postReducer } from 'src/slices/postSlice';
const combinedReducer = combineReducers({
  notifications: notificationReducer,
  clubInfo: clubInfoReducer,
  user: userReducer,

  userclubs: userClubsReucer,
  calendar: calendarReducer,
  classesAndEvents: classesAndEventsReducer,
  schedule: scheduleClassesReducer,
  post: postReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
