import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { PostHistoryType } from 'src/types/post';
import axios from 'src/utils/axios';
import { equal } from 'src/utils/javascript';
import { ClubShortInfo } from 'src/types/clubinfo';
import { Pages } from 'src/types/pages';
interface postState {
  history: PostHistoryType[];
  club: string;
  particularPost: any;
  editPost: object;
  totalPost: number;
}
interface responseDoc extends PostHistoryType {
  _id: string; // to acommendate the returned document _id
}
interface responseData {
  docs: responseDoc[];
  pageNum: number;
  pageSize: number;
}
const initialState: postState = {
  history: [],
  club: '',
  particularPost: {},
  editPost: {},
  totalPost: 0,
};

const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    getPostHistoryData(
      state: postState,
      action: PayloadAction<{
        postHistoryData: PostHistoryType[];
        totalPost?: number;
      }>
    ) {
      const { postHistoryData, totalPost } = action.payload;
      state.history = [...postHistoryData];
      if (totalPost) {
        state.totalPost = totalPost;
      }
    },
    setPostAction(
      state: postState,
      action: PayloadAction<{ postDetail: PostHistoryType }>
    ) {
      const { postDetail } = action.payload;
      state.history = [...state?.history, postDetail];
    },
    emptyPostList(
      state: postState,
      action: PayloadAction<{ postHistory: any }>
    ) {
      const { postHistory } = action.payload;
      state.history = postHistory;
    },
    getPostDetail(state: postState, action: PayloadAction<{ postData: any }>) {
      const { postData } = action.payload;
      state.particularPost = { ...postData, ...state.particularPost };
    },
    emptyParticularPost(state: postState) {
      state.particularPost = {};
    },
    getEditablePost(
      state: postState,
      action: PayloadAction<{ postData: any }>
    ) {
      const { postData } = action.payload;
      state.editPost = postData;
    },
    deletePost(state: postState, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      let postDt = state.history.filter((data: any) => id !== data?.id);

      state.history = postDt;
    },
    emptyEditPost(state: postState) {
      state.editPost = {};
    },
    handleEditPost(
      state: postState,
      action: PayloadAction<{
        editPostData: any;
        history: any;
        particularPost: any;
      }>
    ) {
      const { editPostData, history, particularPost } = action.payload;
      const clonedHistory = history?.map((postData: any) => {
        return equal(postData?.id, editPostData?.id) ? editPostData : postData;
      });
      state.history = clonedHistory;
      state.particularPost = equal(particularPost?.id, editPostData?.id) && {
        ...editPostData,
      };
    },
  },
});

export const getPostHistory =
  (params: {
    clubName: string;
    groupName?: string;
    clubid?: string;
    pageSize?: string;
  }): AppThunk =>
  async (dispatch, getState) => {
    const clubid = getState().userclubs.clublist.find(
      (club: ClubShortInfo) => club.clubName === params.clubName
    ).clubid; // get clubid from store state.userclubs.clublist
    params.clubid = clubid;
    params.pageSize = '10';
    try {
      const response = await axios.get<responseData>('/api/post', {
        params,
      });
      console.log('posts:', response, Array.isArray(response.data.docs));
      if (Array.isArray(response.data.docs)) {
        const postHistory = response.data.docs.map((doc) => {
          return { ...doc, id: doc._id }; // mapping returnded document _id to id.
        });
        dispatch(
          slice.actions.getPostHistoryData({
            postHistoryData: postHistory,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getPageWisePost =
  (rowsPerPage: number, page: number): AppThunk =>
  async (dispatch, getState) => {
    const { selectedClub } = getState().userclubs;
    try {
      /*       const findClubId = clublist?.find(
        (club: any) => club.clubName === selectedClub.
      ); */
      const url = `api/post?clubid=${selectedClub?.clubid}&pageSize=${rowsPerPage}&pageNum=${page}`;
      axios.get<{ page: Pages }>(url).then((response: any) => {
        if (response) {
          const { docs, totalDocCount } = response?.data;
          const postHistory = docs.map((doc) => {
            return { ...doc, id: doc._id }; // mapping returnded document _id to id.
          });
          dispatch(
            slice.actions.getPostHistoryData({
              postHistoryData: postHistory,
              totalPost: totalDocCount,
            })
          );
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

export const setPost =
  (postDetail: PostHistoryType): AppThunk =>
  async (dispatch, getState) => {
    //const clubName = getState().userclubs.selectedClub.clubName;
    const clubid = getState().userclubs.selectedClub.clubid;

    console.log('new post:', postDetail);
    try {
      const response = await axios.post('/api/post', {
        clubid,
        post: {
          ...postDetail,
          email: true,
        },
      });
      console.log('post response:', response);
      if (response.status === 200) {
        dispatch(
          slice.actions.setPostAction({
            postDetail: { ...postDetail, id: response.data.postid },
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
export const emptyPostHistory = (): AppThunk => async (dispatch, getState) => {
  const postHistory = getState().post.history;
  dispatch(slice.actions.emptyPostList({ postHistory }));
};

export const getPostDetail =
  (postData: any): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.getPostDetail({ postData }));
  };

export const emptyParticularPost = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.emptyParticularPost());
};

export const getEditablePost =
  (postData: any): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.getEditablePost({ postData }));
  };

export const deletePost =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    const clubid = getState().post.history[0].clubid;
    try {
      const response = await axios.delete('/api/post', {
        data: { clubid, postid: id },
      });
      if (response.status === 200) {
        dispatch(slice.actions.deletePost({ id }));
      }
      //const postHistory = getState().post?.history;
    } catch (e) {
      console.log(e);
    }
  };
export const emptyEditPost = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.emptyEditPost());
};

export const handleEditPost =
  (editPostData: any): AppThunk =>
  async (dispatch, getState) => {
    console.log('here');
    const { history, particularPost } = getState().post;
    dispatch(
      slice.actions.handleEditPost({ editPostData, history, particularPost })
    );
  };
export const reducer = slice.reducer;

export default slice;
