import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios_mock'; // this is mock api instance.
import { eventDataType } from 'src/types/scheduletype';

interface scheduleState {
  upcomingEventData: eventDataType[];
  pastEventData: eventDataType[];
  particularEvent: any;
}

const initialState: scheduleState = {
  upcomingEventData: [],
  pastEventData: [],
  particularEvent: [],
};

const slice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    getUpcomingScheduleData(
      state: scheduleState,
      action: PayloadAction<{ upcomingEventData: eventDataType[] }>
    ) {
      const { upcomingEventData } = action.payload;
      state.upcomingEventData = [
        ...upcomingEventData,
        ...state.upcomingEventData,
      ];
    },
    getpastScheduleData(
      state: scheduleState,
      action: PayloadAction<{ pastEventData: eventDataType[] }>
    ) {
      const { pastEventData } = action.payload;
      state.pastEventData = [...pastEventData, ...state.pastEventData];
    },
    emptyList(state: scheduleState, action: PayloadAction) {
      state.pastEventData = [];
      state.upcomingEventData = [];
    },
    getSheduleDetail(
      state: scheduleState,
      action: PayloadAction<{ scheduleData: any }>
    ) {
      const { scheduleData } = action.payload;

      state.particularEvent = { ...scheduleData, ...state.particularEvent };
    },
    emptyParticularEvent(state: scheduleState, action: PayloadAction) {
      state.particularEvent = [];
    },
    removeStudentActiopn(
      state: scheduleState,
      action: PayloadAction<{ removeStuId: any; studentData: any }>
    ) {
      const { removeStuId, studentData } = action.payload;
      let data: any = studentData;
      let studentDt = data?.students.filter(
        (data) => !removeStuId.includes(data?.id)
      );
      let temp = { ...data, students: studentDt };
      state.particularEvent = { ...temp };
    },
  },
});
export const reducer = slice.reducer;

export const getUpcomingScheduleData = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get<{
      upcomingEventData: eventDataType[];
    }>('/api/upcomingSchedule');

    dispatch(slice.actions.getUpcomingScheduleData(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const getpastScheduleData = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get<{
      pastEventData: eventDataType[];
    }>('/api/pastSchedule');
    dispatch(slice.actions.getpastScheduleData(response.data));
  } catch (error) {
    console.log(error);
  }
};
export const emptyScheduleList = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.emptyList());
};

export const getSheduleDetail =
  (scheduleData: any): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.getSheduleDetail({ scheduleData }));
  };

export const emptyParticularEvent = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.emptyParticularEvent());
};

export const removeStudent =
  (removeStuId: any, studentData: any): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.removeStudentActiopn({ removeStuId, studentData }));
  };

export default slice;
