import { PostHistoryType } from 'src/types/post';
import mock from 'src/utils/mock';

const postHistoryData: PostHistoryType[] = [
  {
    id: 1,
    title: 'South California Tournament',
    content:
      'As we put it in our ultimate guide to content curation, “Curated content is content created by others that you select to share on social media. This could be a valuable blog post from a company in your field, expert advice from a relevant thought leader, or anything else that you think your audience will appreciate and enjoy.”In other words, if a great article, pin, Tweet or Youtube video already exists out there that your audience would love, why not share it?Curated content can make your brand look like it has its finger on the pulse and like you’re actually there to engage and build community, not just toot your own horn.',
    publishDate: '22 DEC 7:20 PM',
    group: ['Option 1'],
    tags: ['tag1', 'tag2'],
    file: [],
  },
  {
    id: 2,
    title: 'outh California Tournament',
    content:
      'People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of,People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.',
    publishDate: '22 DEC 7:20 PM',
    group: ['Option 1'],
    tags: ['tag1', 'tag2'],
    file: [],
  },
  {
    id: 3,
    title: 'Sout California Tournament',
    content:
      'People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of,People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.',
    publishDate: '22 DEC 7:20 PM',
    group: ['Option 1'],
    tags: ['tag1', 'tag2'],
    file: [],
  },
  {
    id: 4,
    title: 'South California Tournament',
    content:
      'People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of,People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.',
    publishDate: '22 DEC 7:20 PM',
    group: ['Option 1'],
    tags: ['tag1', 'tag2'],
    file: [],
  },
  {
    id: 5,
    title: 'South California Tournament',
    content:
      'People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of,People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.',
    publishDate: '22 DEC 7:20 PM',
    group: ['Option 1'],
    tags: ['tag1', 'tag2'],
    file: [],
  },
  {
    id: 6,
    title: 'South California Tournament',
    content:
      'People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of,People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.',
    publishDate: '22 DEC 7:20 PM',
    group: ['Option 1'],
    tags: ['tag1', 'tag2'],
    file: [],
  },
];

mock.onGet('/api/postHistory').reply(200, { postHistoryData });
