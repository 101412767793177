import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios_mock'; // this is mock api instance.
import { ClassType, EventType } from 'src/types/classeventtype';

interface ClassesAndEventsState {
  classesTypeList: ClassType[];
  eventTypeList: EventType[];
}

const initialState: ClassesAndEventsState = {
  classesTypeList: [],
  eventTypeList: [],
};

const slice = createSlice({
  name: 'classesAndEvents',
  initialState,
  reducers: {
    getClassesTypeList(
      state: ClassesAndEventsState,
      action: PayloadAction<{ classesTypeList: ClassType[] }>
    ) {
      const { classesTypeList } = action.payload;
      state.classesTypeList = [...classesTypeList, ...state.classesTypeList];
    },
    getEventListData(
      state: ClassesAndEventsState,
      action: PayloadAction<{ eventListData: EventType[] }>
    ) {
      const { eventListData } = action.payload;
      state.eventTypeList = [...eventListData, ...state.eventTypeList];
    },
    emptyList(state: ClassesAndEventsState, action: PayloadAction) {
      state.classesTypeList = [];
      state.eventTypeList = [];
    },
    addClassType(
      state: ClassesAndEventsState,
      action: PayloadAction<ClassType>
    ) {
      const classType = action.payload;
      state.classesTypeList = [...state.classesTypeList, classType];
    },
  },
});

export const reducer = slice.reducer;

export const getClassesTypeList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get<{
      classesTypeList: ClassType[];
    }>('/api/classesTypeList');
    dispatch(slice.actions.getClassesTypeList(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const getEventListData = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get<{
      eventListData: EventType[];
    }>('/api/eventList');
    dispatch(slice.actions.getEventListData(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const emptyClassEventList = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.emptyList());
};

export const addClassType =
  (classType: any): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.addClassType(classType));
  };

export default slice;
