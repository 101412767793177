import { ClassType } from 'src/types/classeventtype';
import mock from 'src/utils/mock';

const classesTypeList: ClassType[] = [
  {
    duration: '50 mins',
    classSizeType: 'Group Class',
    classFeatures: ['Group Class', 'In Person'],
    name: 'Level 10 Daily Training',
    url: '/Level-10-Training/',
    virtual: false,
    active: true,
  },
  {
    duration: 'various',
    classSizeType: 'Group Class',
    classFeatures: ['Group Class', 'Virtual'],
    name: 'Level 6 Daily Training',
    url: '/Level-10-Training/',
    virtual: true,
    active: true,
  },
];

mock.onGet('/api/classesTypeList').reply(200, { classesTypeList });
