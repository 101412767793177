import { EventType } from "src/types/classeventtype";
import mock from "src/utils/mock";

const eventListData: EventType[] = [
  {
    thumbnail: "/static/images/tennis.jpeg",
    date: "09 April 2022",
    name: "Tennis Tournament",
    url: "/Level-10-Training/",
    active:true,
    wholeDayEvent:false
  },
  {
    thumbnail: "/static/images/dance.jpeg",
    date: "09 April 2022",
    name: "Dance Club",
    url: "/Level-10-Training/",
    active:true,
    wholeDayEvent:false
  },
];

mock.onGet("/api/eventList").reply(200, { eventListData });
