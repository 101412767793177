import { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';

import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  description?: string; // route description
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              // check each route's routes property first for sub routes. use recursive call to render
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    description: 'Page not found',
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    path: '/privacy',
    component: lazy(() => import('src/views/privacy')),
  },
  {
    exact: true,
    path: '/terms',
    component: lazy(() => import('src/views/Terms')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    description: 'What to do after registration',
    path: '/afterregister',
    component: lazy(() => import('src/views/auth/AfterRegister')),
  },

  /* 	{
		exact: true,
		path: '/register-unprotected', // no guard
		component: lazy(() => import('src/views/auth/RegisterView')),
	}, */
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        description: "Owner's club list",
        path: '/app/dashboard',
        component: lazy(() => import('src/views/host/HostedListView')),
      },
      {
        exact: true,
        description: "User's account setting page",
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView')),
      },
      {
        exact: true,
        description: 'My Club List, as an owner',
        path: '/app/host/myclubs',
        component: lazy(() => import('src/views/host/HostedListView')),
      },
      {
        exact: true,
        path: '/app/host/club-profile/edit/:clubindex',
        component: lazy(() => import('src/views/host/EditClubProfile')),
      },

      {
        exact: true,
        description: 'Club resouces - tesing page',
        path: '/app/club/:clubid/resource',
        component: lazy(() => import('src/views/resources/ClubResourceView')),
      },
      {
        exact: true,
        description: 'Create a new club',
        path: '/app/host/createclub',
        component: lazy(() => import('src/views/host/CreateClubView')),
      },
      {
        exact: true,
        description: 'My clubs, list of my kids clubs as parents',
        path: '/app/parent/myclubs',
        component: lazy(() => import('src/views/parent/ClublistView')),
      },
      {
        exact: true,
        description: 'Group list of the current club',
        path: '/app/host/club/:cluburl/groups',
        component: lazy(() => import('src/views/host/ClubGroups/ClubGroups')),
      },
      {
        exact: true,
        description: 'Club members view',
        path: '/app/host/club/:cluburl/members',
        component: lazy(() => import('src/views/host/ClubMembers/ClubMembers')),
      },
      {
        exact: true,
        path: '/app/host/confirm',
        component: lazy(() => import('src/views/host/HostConfirmView')),
      },
      {
        exact: true,
        path: '/app/dashboard/calendar',
        component: lazy(() => import('src/views/Calendar')),
      },
      {
        exact: true,
        path: '/app/club/dashboard/search',
        component: lazy(() => import('src/views/parent/DashboardView')),
      },
      /*  
      {
        exact: true,
        path: '/app/club/dashboard',
        component: lazy(() => import('src/views/parent/DashboardView')),
      },
           */
      {
        exact: true,
        path: '/app/dashboard/classes-and-events',
        component: lazy(() => import('src/views/host/ClassesAndEvents/index')),
      },
      {
        exact: true,
        path: '/app/dashboard/post-and-notice',
        component: lazy(() => import('src/views/host/PostAndNotice/index')),
      },
      {
        exact: true,
        path: '/app/dashboard/classes-and-events/ScheduleDetail',
        component: lazy(
          () =>
            import('src/views/host/ClassesAndEvents/Schedule/ScheduleDetail')
        ),
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: ['/donation', '/donationsuccess', '/passwordreset'],
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView,
      },
      {
        exact: true,
        path: '/donation',
        component: lazy(() => import('src/views/DonationPage')),
      },
      {
        exact: true,
        path: '/donationsuccess',
        component: lazy(() => import('src/views/DonationSuccess')),
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/passwordreset',
        component: lazy(() => import('src/views/PasswordReset')),
      },
    ],
  },
  {
    description: 'Club Home Page',
    path: '/:cluburl',
    routes: [
      {
        exact: true,
        path: '/:cluburl/signup',
        component: lazy(() => import('src/components/SignupForm')),
      },
      {
        exact: true,
        path: '',
        component: lazy(() => import('src/views/home/HomeView/ClubHome')),
      },
    ],
  },

  {
    path: ['/'],
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView,
      },
    ],
  },
];

export default routes;
